import type { CountryCode } from '~~/src/components/formFields/types';

export function usePhoneField(countryCode: CountryCode, initialValue?: string) {
  const countryPhoneMap: Record<CountryCode, string> = {
    DE: '+49',
    AT: '+43',
    NL: '+31',
  };

  const country = ref(countryPhoneMap[countryCode]);
  const number = ref('');

  if (initialValue) {
    setPhoneValue(initialValue);
  }
  const fullPhoneNumber = ref('');

  watch([country, number], ([countryValue, numberValue]) => {
    if (numberValue) {
      const sanitizedNumber = getSanitizedPhonePart(numberValue);
      if (countryValue.length && sanitizedNumber.length) {
        fullPhoneNumber.value = countryValue + sanitizedNumber;
        return;
      }
    }
    fullPhoneNumber.value = '';
  });

  function getSanitizedPhonePart(phonePart: string) {
    let sanitizedPart = '';
    sanitizedPart = phonePart.replace(/^0+/, '');
    return sanitizedPart.replace(/^\s/g, '');
  }

  function setPhoneValue(value: string) {
    if (value.startsWith('+')) {
      country.value = value.slice(0, 3);
      number.value = value.slice(3);
    } else {
      country.value = countryPhoneMap[countryCode];
      number.value = value;
    }
  }

  return {
    country,
    number,
    fullPhoneNumber,
    countryPhoneMap,
    setPhoneValue,
  };
}
