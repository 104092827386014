<template>
  <DynamicValidationRow
    name="phone"
    label="formFields.phone.label"
    validation-message="forms.phoneValidationMessage"
    :required="required"
  >
    <TextInput
      v-model="country"
      name="phoneCountry"
      width="basis-3/12"
      placeholder="+49"
      :disabled="true"
      :required="false"
    />
    <TextInput
      v-model="number"
      name="phoneNumber"
      width="basis-full"
      :validation="[['matches', /^[0-9]*$/]]"
      :maxlength="24"
      placeholder="formFields.phone.phoneNumber"
      :required="required"
      :validation-visibility="validationVisibility"
    />

    <FormKit v-model="fullPhoneNumber" type="hidden" name="fullPhoneNumber" />
  </DynamicValidationRow>
</template>
<script setup lang="ts">
import type {
  CountryCode,
  ValidationVisibility,
} from '~~/src/components/formFields/types';
import { TextInput, DynamicValidationRow } from '@/complib';
import { usePhoneField } from './usePhone';
import { FormKit } from '@formkit/vue';
import type { FormkitFieldProps } from '~/@types/formie';

interface CanonicalPhoneFieldProps extends FormkitFieldProps {
  countryCode: CountryCode;
}

const props = withDefaults(defineProps<CanonicalPhoneFieldProps>(), {
  required: true,
  countryCode: 'DE' as CountryCode,
  validationVisibility: 'blur' as ValidationVisibility,
});

const value = defineModel({
  default: '',
});

const { country, number, fullPhoneNumber, countryPhoneMap, setPhoneValue } =
  usePhoneField(props.countryCode, value.value);

watch(
  () => fullPhoneNumber.value,
  (nV) => {
    value.value = nV;
  },
);

watch(
  () => value.value,
  (nV) => setPhoneValue(nV),
);

watch(
  () => props.countryCode,
  (newCountryCode) => {
    country.value = countryPhoneMap[newCountryCode];
  },
);

const emit = defineEmits<{
  (e: 'change', data: string): void;
}>();

watch(
  () => fullPhoneNumber.value,
  (newFullPhoneNumber) => {
    emit('change', newFullPhoneNumber);
  },
);
</script>
